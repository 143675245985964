/* eslint-disable import/no-extraneous-dependencies */

import {
  AddressSchema,
  ComplexityFormValues,
  ComplexitySchema,
  ContactFormValues,
  ContactSchema,
  InquiryFormValues,
  InquirySchema,
  extractAddressLocationNameContactObject,
  extractCaseComplexityProps,
  extractCustomerAddressProps,
  extractCustomerContactProps,
  extractCustomerPersonProps,
} from '@adac/core-view';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { showNotification } from 'react-admin';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import * as yup from 'yup';

import {
  AddressFormValues,
  Cancellation,
  ComplexityTypes,
  CustomerDataWithSms,
  ProductTypes,
  __,
  extractLocationCoords,
  generateDummyCommisioningId,
  getApiRoutes,
} from '@adac/core-model';
// import StoresContext from '../../stores';

import saveManualCase from '../../actions/saveManualCase';

import { useClearCaseOnLeavingPage } from '../../helpers/customerData';
import { RAShowNotificationProps } from '../common/react-admin/interfaces';
import { NewRegularCaseForm } from './NewRegularCaseForm';

const BaseCaseSchema = yup.object().shape({
  commissioningReference: yup.string().max(60).required(),
  contactManagerId: yup.string().required(),
  damageNumber: yup.string().optional().nullable(),
});

export const NewCaseSchema = AddressSchema.concat(ContactSchema).concat(ComplexitySchema).concat(InquirySchema).concat(BaseCaseSchema);

interface ContactFormValuesWithSms extends ContactFormValues {
  receiveStatusViaSMS: boolean;
}

export interface NewRegularCaseFormValues extends AddressFormValues, ContactFormValuesWithSms, InquiryFormValues, ComplexityFormValues {
  commissioningReference: string;
  contactManagerId?: string; // TODO: release/5 rename to more self-explaining and apply better typing
  companyId: number;
  description: string;
  productType: ProductTypes;
  productNumber: string | undefined;
  damageNumber: string | undefined;
  scheduledTs: Date | null;
}

const previewUrl = '/newcase/offer';

interface NewRegularCaseProps extends RAShowNotificationProps, RouteComponentProps {
  caseType: string;
  currentNewCase: CustomerDataWithSms | null;
  saveCustomerData: (data: CustomerDataWithSms | null) => {
    type: string;
    payload: CustomerDataWithSms | null;
  };
}

const initialValues = (currentNewCase: CustomerDataWithSms | null) => ({
  ...extractAddressLocationNameContactObject(currentNewCase),
  description: '',
  productType: ProductTypes.STANDARD,
  productNumber: (Math.random() * 1000).toFixed(0),
  damageNumber: (Math.random() * 1000).toFixed(0),
  commissioningReference: generateDummyCommisioningId(Date.now),
  receiveStatusViaSMS: true,
  contactManagerId: undefined,
  complexity: ComplexityTypes.CLOSED,
  companyId: 0,
  scheduledTs: null,
});

const NewRegularCase = ({ currentNewCase, ...raProps }: NewRegularCaseProps) => {
  useClearCaseOnLeavingPage(raProps.history, raProps.saveCustomerData, previewUrl);

  const handleSubmit = async (values: NewRegularCaseFormValues, actions: FormikActions<NewRegularCaseFormValues>) => {
    const companyId = Number(values.contactManagerId?.match(/^\d+/)) || 0;

    raProps.saveCustomerData({
      ...extractCustomerAddressProps(values),
      ...extractCustomerPersonProps(values),
      ...extractCustomerContactProps(values),
      ...extractCaseComplexityProps(values),
      ...extractLocationCoords(values),
      commissioningReference: `${values.commissioningReference}/${values.contactManagerId}`,
      companyId,
      productType: values.productType,
      productNumber: values.productNumber || null,
      damageNumber: values.damageNumber || null,
      description: values.description,
      receiveStatusViaSMS: values.receiveStatusViaSMS,
      caseType: raProps.caseType,
      auctionCreatedAt: new Date(),
      scheduledTs: values.scheduledTs || null,
      consents: {
        serviceTerms: false,
        privacyPolicy: false,
        withdrawalServiceStart: false,
        withdrawalRightEnds: false,
        acknowledgeDamage: false,
        confirmAuthorization: false,
      },
      cancellation: Cancellation.NO,
    });
    try {
      actions.setSubmitting(false);
      raProps.history.push(previewUrl);
    } catch (err) {
      raProps.showNotification(__(err?.toString() ?? 'Error'), 'warning');
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues(currentNewCase)} isInitialValid={!!currentNewCase} validationSchema={NewCaseSchema} onSubmit={handleSubmit}>
      <NewRegularCaseForm handleCancel={() => raProps.history.push(getApiRoutes(false, '').backoffice.case.list.open)} />
    </Formik>
  );
};

export default connect(
  (state: any) => ({
    currentNewCase: state.customerData,
  }),
  {
    saveCustomerData: saveManualCase,
    showNotification,
  }
)(withRouter(NewRegularCase));
