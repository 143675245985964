import * as H from 'history';

export interface UseRouterParamProps {
  resource?: string;
  history?: {
    location?: H.Location<H.LocationState>;
  };
}

export const useRouterParam = (props: UseRouterParamProps, id: string) => {
  // console.log('useRouterParam props', props);
  const { resource, history } = props;
  const regex = new RegExp(`.*${`${resource}`.replace(new RegExp(`(:${id})`), `(?<${id}>\\d+)`)}.*`);
  // console.log('useRouterParam', history?.location?.pathname, regex, history?.location?.pathname?.match(regex));
  const found = history?.location?.pathname?.match(regex);
  return found && found.groups && found.groups[id];
};

export const useRouterParamCompanyId = (props: UseRouterParamProps) => useRouterParam(props, 'companyId');
