import styled from 'styled-components';
import moment from 'moment';
import { CaseCommentMeta, RecordActionTimes } from '@adac/core-model';
import { ChildrenProps } from '@adac/core-view';

export const CaseStatusCommentItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  background-color: #f3f3f4;
  margin-bottom: 8px;
`;

const CaseStatusCommentItemDetails = styled.div`
  /* margin-left: 16px; */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const CaseStatusCommentItemDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h4 {
    margin-right: auto;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.5;
    color: #000;
  }
  span {
    margin-left: auto;
    font-size: 16px;
    line-height: 1.5;
    text-align: right;
    color: #b2b2b2;
  }
`;

const CaseStatusCommentItemDetailsText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #000;
`;

export interface CaseStatusCommentItemProps extends ChildrenProps, Partial<CaseCommentMeta>, RecordActionTimes {}

const CaseStatusCommentItem = ({ name, createdAt, children }: CaseStatusCommentItemProps) => (
  <CaseStatusCommentItemWrapper>
    <CaseStatusCommentItemDetails>
      {/* <UserAvatar id={userId} /> */}
      <CaseStatusCommentItemDetailsHeader>
        <h4>{name}</h4>
        <span>{moment(createdAt).format('DD-MM-YY HH:mm')}</span>
      </CaseStatusCommentItemDetailsHeader>
      <CaseStatusCommentItemDetailsText>{children}</CaseStatusCommentItemDetailsText>
    </CaseStatusCommentItemDetails>
  </CaseStatusCommentItemWrapper>
);

export default CaseStatusCommentItem;
