import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withRouter, RouteComponentProps } from 'react-router';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Divider, FullWidthLayout, Title, FlexView, CaseStatusHeaderTextContainer, ChildrenProps } from '@adac/core-view';

import { CaseWithAllRelationsDTO, __, getClaimReference, getCompanyTypeWithContactManagerId } from '@adac/core-model';

const CaseStatusHeaderWrapper = styled(FullWidthLayout)`
  padding-top: 24px;
`;

const CaseStatusHeaderBlock = styled.div`
  padding-left: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CaseStatusHeaderBackButtonContainer = styled.div`
  padding-right: 16px;
  padding-top: 4px;
  cursor: pointer;
`;

const CaseStatusHeaderTitle = styled(Title)`
  color: black;
  font-size: 24px;
  font-weight: bold;
`;

const CaseStatusHeaderSubtitle = styled.h4`
  font-family: MiloADAC;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  color: black;
`;
const CaseStatusHeaderBoldSpan = styled.span`
  font-weight: bold;
`;

const CommissioningReference = styled.span`
  margin-left: 4px;
  color: black;
`;
const DividingCircle = styled.span`
  &::before {
    content: '•';
    margin: 0px 3px;
  }
`;

interface CaseHeaderContentProps extends ChildrenProps {
  caseDetails: CaseWithAllRelationsDTO | null;
}

export const CaseHeaderContent = ({ caseDetails, children }: CaseHeaderContentProps) => {
  const { commissioningReference } = caseDetails?.case || {};
  const formattedCommissioningReference = commissioningReference
    ? `${getClaimReference(commissioningReference)}-${getCompanyTypeWithContactManagerId(commissioningReference)}`
    : '';

  return (
    <>
      <CaseStatusHeaderTextContainer>
        <CaseStatusHeaderTitle>
          {caseDetails?.case?.customerAddressStreet} {caseDetails?.case?.customerAddressNumber}, {caseDetails?.case?.customerAddressZip}{' '}
          {caseDetails?.case?.customerAddressCity}
        </CaseStatusHeaderTitle>

        <CaseStatusHeaderSubtitle>
          <CaseStatusHeaderBoldSpan>{__('Digital')}</CaseStatusHeaderBoldSpan>
          <DividingCircle />
          <CommissioningReference>{formattedCommissioningReference}</CommissioningReference>
          <DividingCircle />
          <CaseStatusHeaderBoldSpan>{__('Case Number')}</CaseStatusHeaderBoldSpan>:{`${caseDetails?.case?.id} (${caseDetails?.token})`}
        </CaseStatusHeaderSubtitle>
      </CaseStatusHeaderTextContainer>
      <FlexView marginLeft='auto' paddingRight='24px' right>
        {children}
      </FlexView>
    </>
  );
};

interface CaseStatusHeaderProps extends RouteComponentProps, ChildrenProps {}

const CaseStatusHeader = ({ children, history }: CaseStatusHeaderProps) => (
  <CaseStatusHeaderWrapper>
    <CaseStatusHeaderBlock>
      <CaseStatusHeaderBackButtonContainer>
        <ArrowBackIcon onClick={() => history.goBack()} />
      </CaseStatusHeaderBackButtonContainer>
      {children}
    </CaseStatusHeaderBlock>
    <Divider fullWidth style={{ marginTop: '24px' }} />
  </CaseStatusHeaderWrapper>
);

export default withRouter(CaseStatusHeader);
