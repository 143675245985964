import { Form, Field, FieldProps, useFormikContext } from 'formik';
import { Select, MenuItem } from '@material-ui/core';

import { TextField, Button, ColumnLayout, FullWidthLayout, ButtonTitle, ErrorMessage, FlexView, Children, VariantWorkaround } from '@adac/core-view';

import { __, UserRoleType, UserRole, AvailableNotificationSettingValues as Channel } from '@adac/core-model';

import Header from '../common/Header';

import { UserFormValues } from './UserFormValues';
import { FormStyles, FormLayout } from '../common/FormStyles';
import { ChangeEvent, ChannelOptionFields, ChannelValueFields } from './UserSignInChannelForm';

interface UserFormTypes {
  user: UserFormValues;
  roles: UserRoleType[];
  children?: Children;
  onCancel: () => void;
}

export const UserForm = ({ children, user, roles, onCancel }: UserFormTypes) => {
  const { values, isSubmitting, isValid, errors, setFieldValue } = useFormikContext<UserFormValues>();

  function onUsernameChange(e: ChangeEvent) {
    setFieldValue('username', e.target.value);
    if (values.channel === Channel.EMAIL) {
      setFieldValue('address', e.target.value);
    }
  }

  function onChannelChange(e: ChangeEvent) {
    setFieldValue('channel', e.target.value);
    if (e.target.value === Channel.EMAIL) {
      setFieldValue('address', values.username);
    }
    if (e.target.value === Channel.SMS) {
      setFieldValue('address', user?.phone || '');
    }
  }

  return (
    <Form>
      <Header goBack={onCancel} title={user?.username || __('New user')} id={user?.id} />
      <FormStyles>
        <FormLayout>
          <ColumnLayout ratio='1fr'>
            <Field
              name='role'
              render={({ field }: FieldProps<'role', UserFormValues>) => (
                <VariantWorkaround variant='outlined'>
                  <Select
                    // variant="outlined"
                    {...field}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role} selected={values?.roles?.includes(role)} value={role}>
                        {__(`Role:${role}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </VariantWorkaround>
              )}
            />
          </ColumnLayout>
          {errors.role && <ErrorMessage>{__(errors.role)}</ErrorMessage>}

          <ColumnLayout ratio='1fr 1fr' gap='20px'>
            <Field
              name='id'
              render={({ field }: FieldProps<'id', UserFormValues>) => (
                <TextField {...field} disabled badgeEqualsPlaceholder badgeTitle={__('User Id')} />
              )}
            />

            <Field
              name='username'
              render={({ field }: FieldProps<'username', UserFormValues>) => (
                <TextField {...field} badgeEqualsPlaceholder badgeTitle={__('Username')} onChange={(e) => onUsernameChange(e)} />
              )}
            />
          </ColumnLayout>
          {errors.username && <ErrorMessage>{__(errors.username)}</ErrorMessage>}

          <ColumnLayout ratio='1fr'>
            <Field
              name='name'
              render={({ field }: FieldProps<'name', UserFormValues>) => <TextField {...field} badgeEqualsPlaceholder badgeTitle={__('Name')} />}
            />
          </ColumnLayout>
          {errors.name && <ErrorMessage>{__(errors.name)}</ErrorMessage>}

          {values.role !== UserRole.API && (
            <FlexView column>
              <ChannelOptionFields onChange={(e) => onChannelChange(e)} />
              <ChannelValueFields />
            </FlexView>
          )}
          {errors.password && <ErrorMessage>{__(errors.password)}</ErrorMessage>}

          {values.role !== UserRole.API && (
            <ColumnLayout ratio='1fr 1fr'>
              <Field
                name='password'
                render={({ field }: FieldProps<'password', UserFormValues>) => (
                  <TextField {...field} type='password' badgeEqualsPlaceholder badgeTitle={__('Password')} />
                )}
              />
              <Field
                name='confirmPassword'
                render={({ field }: FieldProps<'confirmPassword', UserFormValues>) => (
                  <TextField {...field} type='password' badgeEqualsPlaceholder badgeTitle={__('Confirm password')} />
                )}
              />
            </ColumnLayout>
          )}
          {errors.password && <ErrorMessage>{__(errors.password)}</ErrorMessage>}

          <ColumnLayout ratio='1fr 1fr'>
            <FullWidthLayout style={{ width: 'auto' }}>
              <Button type='button' info disabled={isSubmitting} isLoading={isSubmitting} onClick={() => onCancel()}>
                <ButtonTitle>{__('Cancel')}</ButtonTitle>
              </Button>
            </FullWidthLayout>

            <FullWidthLayout>
              <Button type='submit' cta disabled={isSubmitting || !isValid} isLoading={isSubmitting}>
                <ButtonTitle>{__('Update')}</ButtonTitle>
              </Button>
            </FullWidthLayout>
          </ColumnLayout>

          {children && <ColumnLayout ratio='1fr'>{children}</ColumnLayout>}
        </FormLayout>
      </FormStyles>
    </Form>
  );
};
