import styled from 'styled-components';
import { showNotification as showNotificationAction } from 'react-admin';
import { connect } from 'react-redux';
import {
  Button,
  useAsyncComponent,
  LocalizableError,
  AnimatedHourglassIcon,
  AsyncComponentComponentProps,
  ChildrenProps,
  ButtonStylesProps,
} from '@adac/core-view';

import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined';
import QueueOutlinedIcon from '@material-ui/icons/QueueOutlined';
import CancelPresentationOutlinedIcon from '@material-ui/icons/CancelPresentationOutlined';

import { ReportUrl } from '../../dataProvider/statistics';
import { RAShowNotificationProps } from '../common/react-admin/interfaces';

interface DownloadButtonProps extends AsyncComponentComponentProps<ReportUrl>, ButtonStylesProps {}

const DownloadButtonStyles = styled(Button)<DownloadButtonProps>`
  width: 330px;
  margin-top: 32px;

  ${({ error }) =>
    !!error &&
    `
    button {
      background-color: red;
    }
  `}
  ${({ hasLoaded }) =>
    hasLoaded &&
    `
    button, a {
      border: solid 3px green;
    }
  `}

  svg {
    margin-right: 6px;
    width: 20px;
    height: 20px;
  }
`;

const DownloadButton = ({ children, hasLoaded, ...props }: DownloadButtonProps) => (
  <DownloadButtonStyles cta {...props}>
    <>
      {props.isLoading && <AnimatedHourglassIcon />}
      {props.error && <CancelPresentationOutlinedIcon />}
      {hasLoaded && props.resource?.url && <QueueOutlinedIcon />}
      {!props.isLoading && !props.error && !props.resource?.url && <AddBoxOutlinedIcon />}
      {children}
    </>
  </DownloadButtonStyles>
);

interface DownloadReportProps extends ChildrenProps, RAShowNotificationProps {
  action: () => Promise<ReportUrl>;
}
const DownloadReport = ({ action, children, showNotification }: DownloadReportProps) => {
  // const [url, setUrl] = React.useState<string | null>(null);
  const onError = (error: Error) => {
    const errorMessage = (error as LocalizableError)?.toLocalizedString?.();
    if (errorMessage) {
      showNotification(errorMessage, 'warning');
    }
  };

  const {
    resource,
    setResource,
    Component: GenerateReportButton,
  } = useAsyncComponent<ReportUrl | null>({
    onClick: action,
    // onResourceLoaded: ({ url: u }) => setUrl(u),
    Component: DownloadButton,
    enableAfterLoading: true,
    onError,
  });

  return !resource?.url ? (
    <GenerateReportButton>{children}</GenerateReportButton>
  ) : (
    <DownloadButtonStyles cta link={resource.url} onClick={() => setResource(null)} target='_download_csv' hasLoaded>
      <SaveAltOutlinedIcon />
      <>{children}</>
    </DownloadButtonStyles>
  );
};

export const ConnectedDownloadReport = connect(null, {
  showNotification: showNotificationAction,
})(DownloadReport);
