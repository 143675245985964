/* eslint-disable import/no-extraneous-dependencies */

import {
  __,
  allowedStatesForClosing,
  CaseActions,
  CaseLogRecord,
  CaseStatusList,
  filesNotAvailableStates,
  getCaseActionName,
  getOneCaseStatusEventName,
  isCaseStatusEvent,
  UserRole,
} from '@adac/core-model';
import {
  CaseDocuments,
  CaseStatusEventHistory,
  CaseStatusHeaderPlaceholder,
  CaseStatusTitle,
  createAddressString,
  DocumentStyles,
  EventHistoryCheckboxes,
  extractConfirmedAddressObject,
  FilePlaceholder,
  FlexView,
  GeneralButton,
  Icon,
  InvoiceDocumentLink,
  Loader,
  MapContainer,
  Separator,
  useCaseDetails,
  useSocketClient,
  View,
} from '@adac/core-view';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { showNotification } from 'react-admin';
import { getCaseDetails } from '../../dataProvider/getCase';
import CustomCard from '../common/CustomCard';
import { RAShowNotificationProps } from '../common/react-admin/interfaces';
import { CaseCompanyInfo } from './caseStatusComponents/CaseCompanyInfo';
import { CaseDriverInfo } from './caseStatusComponents/CaseDriverInfo';
import CaseStatusComment from './caseStatusComponents/CaseStatusComment';
import CaseStatusDetails from './caseStatusComponents/CaseStatusDetails';
import CaseStatusHeader, { CaseHeaderContent } from './caseStatusComponents/CaseStatusHeader';
import CaseStatusInfoBlock from './caseStatusComponents/CaseStatusInfoBlock';
import CaseStatusSection from './caseStatusComponents/CaseStatusSection';

import setClosingCaseAction, { CaseCloseType } from '../../actions/setClosingCase';
import { useUserRole } from '../../helpers/useUserRole';
import { EditReportModal } from './caseStatus/EditReportModal';

export const Card = styled(CustomCard)`
  display: flex;
  flex-direction: column;
  place-items: initial;
`;

const CustomMapStyles = styled.div`
  height: 240px;
  max-width: 95vw;
  width: calc(100vw - 240px - 30px);
  overflow: hidden;
`;

interface CaseStatusProps extends RAShowNotificationProps, RouteComponentProps<{ caseId: string }> {
  setClosingCase: (props: CaseCloseType) => void;
}

const CaseStatus = (raProps: CaseStatusProps): JSX.Element => {
  const [isEditingDocuments, setIsEditingDocuments] = React.useState(false);
  const socket = useSocketClient();
  const [allLogType, setAllLogType] = React.useState(true);
  const { caseDetails, isLoading, refreshCaseData, activeEvent, customerCoords, driverCoords, confirmedAddress } = useCaseDetails(
    [raProps.match.params.caseId],
    getCaseDetails
  );

  const userRole = useUserRole();
  const isManager = userRole.names.includes(`${UserRole.MANAGER}`);
  const isCloseable = isManager && allowedStatesForClosing.includes(activeEvent);

  const hideReportsOnEventForReadOnly = !isManager && caseDetails?.status.event === CaseStatusList.IMAGES_UPDATED;

  const processedCaseLogs = useProcessedCaseLogs(allLogType, caseDetails?.logs, hideReportsOnEventForReadOnly);

  const onCloseButtonClick = async () => {
    if (caseDetails?.case) {
      await raProps.setClosingCase(caseDetails?.case);
    }
  };

  React.useEffect(() => {
    // TODO: evaluate if we can directly use socket data instead of refreshCaseData
    socket.on(getOneCaseStatusEventName(caseDetails?.case?.id), refreshCaseData);
    return () => {
      socket.off(getOneCaseStatusEventName(caseDetails?.case?.id));
    };
  }, [socket, caseDetails, refreshCaseData]);

  const shouldAllowEditDocuments =
    userRole.names.includes(`${UserRole.MANAGER}`) &&
    caseDetails?.status.event === CaseStatusList.CASE_COMPLETED &&
    caseDetails?.case.commissionReport;

  return (
    <Card>
      <CaseStatusHeader>
        <Loader isLoading={isLoading} component={CaseStatusHeaderPlaceholder}>
          <CaseHeaderContent caseDetails={caseDetails}>
            <>{isCloseable ? <GeneralButton info onClick={onCloseButtonClick} title={__('Close case')} /> : ''}</>
          </CaseHeaderContent>
        </Loader>
      </CaseStatusHeader>

      <CustomMapStyles>
        <MapContainer
          style={{
            height: '240px',
          }}
          customerPosition={customerCoords}
          driverPosition={driverCoords}
          markerPadding={80}
        />
      </CustomMapStyles>

      <CaseStatusDetails isLoading={isLoading} caseDetails={caseDetails?.case}>
        <>
          <CaseStatusInfoBlock area='address' title={__(confirmedAddress ? 'Address given by customer' : 'Customer Address')}>
            <Loader isLoading={isLoading}>{createAddressString(caseDetails?.case)}</Loader>
          </CaseStatusInfoBlock>
          {confirmedAddress && (
            <CaseStatusInfoBlock area='address2' title={__('Confirmed Customer Address')}>
              <Loader isLoading={isLoading}>{createAddressString(extractConfirmedAddressObject(confirmedAddress))}</Loader>
            </CaseStatusInfoBlock>
          )}
        </>
      </CaseStatusDetails>

      <Separator />

      <CaseStatusSection paddingTop='0 !important' paddingBottom='0 !important'>
        <CaseCompanyInfo isLoading={isLoading} companyInfo={caseDetails?.companyInfo} />

        <CaseDriverInfo isLoading={isLoading} driverInfo={caseDetails?.driverInfo} />
      </CaseStatusSection>

      <CaseStatusComment
        isLoading={isLoading}
        caseToken={caseDetails?.token}
        caseId={Number(caseDetails?.case?.id)}
        items={caseDetails?.comments}
        refreshCaseData={refreshCaseData}
      />

      <CaseStatusEventHistory isLoading={isLoading} history={raProps.history} items={caseDetails?.logs} filteredItems={processedCaseLogs}>
        <EventHistoryCheckboxes checked={allLogType} onChange={(value: boolean) => setAllLogType(value)} />
      </CaseStatusEventHistory>

      {shouldAllowEditDocuments && (
        <EditReportModal
          on={isEditingDocuments}
          caseToken={caseDetails?.case.token}
          setIsOpen={setIsEditingDocuments}
          showNotification={raProps.showNotification}
        />
      )}

      {!filesNotAvailableStates.includes(activeEvent) && (
        <DocumentStyles>
          <FlexView>
            <CaseStatusTitle>{__('Informationen über den Kunden')}</CaseStatusTitle>
            {shouldAllowEditDocuments && (
              <View onClick={() => setIsEditingDocuments(true)}>
                <Icon name='edit' color='black' style={{ marginLeft: '10px', cursor: 'pointer' }} />
              </View>
            )}
          </FlexView>
          <Loader isLoading={isLoading || hideReportsOnEventForReadOnly} count={4} component={FilePlaceholder}>
            <CaseDocuments
              token={caseDetails?.case?.token}
              commissionReport={caseDetails?.case?.commissionReport}
              commissionExtraDamage={caseDetails?.case?.commissionExtraDamage}
              finalStatusReport={caseDetails?.case?.finalStatusReport?.customer}
            >
              {!!caseDetails?.case?.invoiceDocument && (
                <InvoiceDocumentLink token={caseDetails?.case?.token} {...caseDetails?.case?.invoiceDocument} />
              )}
            </CaseDocuments>
          </Loader>
        </DocumentStyles>
      )}
    </Card>
  );
};

const mapStateToPropsCaseStatus = (state: any) => ({
  closingCase: state.closingCase,
});

export default connect(mapStateToPropsCaseStatus, {
  showNotification,
  setClosingCase: setClosingCaseAction,
})(CaseStatus);

function useProcessedCaseLogs(
  includeAllLogTypes: boolean,
  logs: CaseLogRecord[] | undefined,
  hideReportsOnEventForReadOnly: boolean
): CaseLogRecord[] | undefined {
  const userRole = useUserRole();
  const isReadonly = userRole.names.includes(`${UserRole.SYS}`);

  return useMemo(() => {
    if (!logs) return logs;

    const eventOccurrences: Record<string, number> = {};

    /**
     * Determines if an event is relevant for report processing.
     * Relevant events are Opening, Extra Damage, and Final Reports.
     */
    const isRelevantEvent = (event: string) =>
      [
        getCaseActionName(CaseActions.CREATE_OPENING_REPORT),
        getCaseActionName(CaseActions.CREATE_EXTRA_DAMAGE_REPORT),
        getCaseActionName(CaseActions.CREATE_FINAL_REPORT),
      ].includes(event);

    /**
     * Processes logs to clear report data for non-latest entries in read-only mode.
     */
    const processedLogs = isReadonly
      ? logs.map((log) => {
          const { event } = log;
          eventOccurrences[event] = (eventOccurrences[event] || 0) + 1;

          if (isRelevantEvent(event) && (hideReportsOnEventForReadOnly || eventOccurrences[event] > 1)) {
            return {
              ...log,
              case: {
                ...log.case,
                commissionReport: undefined,
                commissionExtraDamage: undefined,
                finalStatusReport: undefined,
              },
              data: {},
            };
          }

          return log;
        })
      : logs;

    const includedLogs = includeAllLogTypes
      ? (processedLogs as CaseLogRecord[])
      : (processedLogs.filter((log) => log.event && isCaseStatusEvent(log.event)) as CaseLogRecord[]);

    return includedLogs;
  }, [includeAllLogTypes, hideReportsOnEventForReadOnly, isReadonly, logs]);
}
