/* eslint-disable import/no-extraneous-dependencies */

import { Create, CREATE, getResources, showNotification as showNotificationAction } from 'react-admin';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import { connect } from 'react-redux';

import { getApiRoutes, RegionRecord, __ } from '@adac/core-model';

import { HolidayForm, HolidaySchema } from './HolidayForm';
import { HolidayFormValues } from './HolidayFormValues';

import dataProvider from '../../dataProvider';
import { FormProps } from '../common/react-admin/interfaces';
import { useRegionList } from '../../dataProvider/useRegionList';

export const getRegionIdsForRegions = (regions: RegionRecord[], regionNames?: string[]) =>
  regionNames?.includes(__('ALL REGION'))
    ? regions.filter((region) => region.name === __('ALL REGION')).map((region) => region.id)
    : regions.filter((region) => regionNames?.includes(region.name)).map((region) => region.id);

const HolidayCreateForm = (raProps: FormProps<HolidayFormValues>) => {
  const { regions } = useRegionList();
  const holiday = raProps.record;

  const onSubmit = async (values: HolidayFormValues, actions: FormikActions<HolidayFormValues>) => {
    const newHolidayData = {
      day: values.day,
      month: values.month,
      year: values.year || null,
      name: values.name || null,
      regionId: getRegionIdsForRegions(regions, values.regionNames) || [],
    };
    try {
      await dataProvider(CREATE, raProps.resource, { data: newHolidayData });
      actions.setSubmitting(false);
      raProps.history.push(getApiRoutes(false, '').backoffice.holiday.list);
    } catch (error) {
      raProps.showNotification(__(error?.toString() ?? 'Error'), 'warning');
      console.log(error);
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={holiday} validationSchema={HolidaySchema} onSubmit={onSubmit} isInitialValid={false}>
      <HolidayForm holiday={holiday} onCancel={() => raProps.history.push(getApiRoutes(false, '').backoffice.holiday.list)} />
    </Formik>
  );
};

const mapEditStateToProps = (state: any) => ({
  resources: getResources(state),
});

const ConnectedHolidayCreateForm = connect(mapEditStateToProps, {
  showNotification: showNotificationAction,
})(HolidayCreateForm);

export default (raProps: any) => (
  <Create {...raProps}>
    <ConnectedHolidayCreateForm {...raProps} />
  </Create>
);
