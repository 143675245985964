import { ChildrenProps } from '@adac/core-view';
import styled, { css } from 'styled-components';

export const CaseDetailsTitleWithText = styled.div<{ area?: string }>`
  display: flex;
  flex-direction: column;
  padding-bottom: 8px;
  ${(props) =>
    props.area &&
    css`
      grid-area: ${props.area};
    `}
`;

const CaseDetailsTitle = styled.h6`
  font-size: 12px;
  font-weight: bold;
  line-height: 1.67;
  color: #000;
`;

const CaseDetailsText = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #000;
`;

interface CaseStatusInfoBlockProps extends ChildrenProps {
  title: string;
  area?: string;
}

const CaseStatusInfoBlock = ({ title, children, ...styles }: CaseStatusInfoBlockProps): JSX.Element => (
  <CaseDetailsTitleWithText {...styles}>
    <CaseDetailsTitle>{title}</CaseDetailsTitle>
    <CaseDetailsText>{children}</CaseDetailsText>
  </CaseDetailsTitleWithText>
);

export default CaseStatusInfoBlock;
