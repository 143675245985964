import { __ } from '@adac/core-model';
import { DateInput, Filter, NumberInput, TextInput } from 'react-admin';

export default (props: any) => (
  <Filter {...props}>
    <TextInput label={__('ID')} source='id' allowEmpty />
    <TextInput label={__('Commissioning')} source='commissioningReference' allowEmpty />
    <TextInput label={__('First Name')} source='customerFirstName' allowEmpty />
    <TextInput label={__('Last Name')} source='customerFamilyName' allowEmpty />
    <TextInput label={__('Customer City')} source='customerAddressCity' allowEmpty />
    <TextInput label={__('ProductType')} source='productType' allowEmpty />
    <TextInput label={__('Customer Address')} source='customerAddressStreet' allowEmpty />
    <TextInput label={__('Customer Phone')} source='customerPhone' allowEmpty />
    <TextInput label={__('Description')} source='description' allowEmpty />
    <TextInput label={__('Zip Code')} source='customerAddressZip' allowEmpty />
    <NumberInput min={0} max={Infinity} label={__('SND ID')} source='companyId' allowEmpty />
    <DateInput label={__('createdAtFrom')} source='createdAtFrom' />
    <DateInput label={__('createdAtTo')} source='createdAtTo' />
    {props.sndCompany && <TextInput label={__('SND Name')} source='companyName' allowEmpty />}
    {props.children}
    <TextInput label={__('token')} source='token' allowEmpty />

    {/* <TextInput label="Time of Order" source="timeOfOrder" reference={OPEN_CASES} allowEmpty /> */}

    {/* Example of reference input -> instead of typing, you can select from existing values */}
    {/* <ReferenceInput label="Customer Last Name" source="customerFamilyName" reference={OPEN_CASES} allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput> */}
  </Filter>
);
