import { CompanyTypes } from '../types/Company.js';
import { UserRole } from '../types/UserRoles.js';
export const getCompanyId = (company) => `${company.id}/paris-AMP-SD-${company.type.toUpperCase()}-${company.contactManagerId}`;
export const extractContactManagerId = (commissioningReference) => { var _a; return (_a = commissioningReference.match(/(\d{9,10})\w*$/)) === null || _a === void 0 ? void 0 : _a[0]; };
export const contactManagerIdCorePattern = '(\\d{9,10})';
export const contactManagerIdPattern = new RegExp(`^${contactManagerIdCorePattern}$`);
export const getPasswordRegex = () => new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&_]).{14,}$');
export const realMessageTypeRegexp = /(sms)|(email)|(sms_vonage)/;
export const allMessageTypeRegexp = /(sms)|(email)|(off)|(sms_vonage)/;
export const disabledMessageTypeRegexp = /(off)/;
export const activePartnerMessageTypeRegexp = /(sms_vonage)|(email)/;
export const allPartnerMessageTypeRegexp = /(email)|(off)|(sms_vonage)/;
export const allowedUserSignInSettingRegexp = /(sms)|(email)/;
export const isCompanyActive = (company) => {
    const today = new Date(Date.now());
    const notYetActive = company.activeFrom && new Date(company.activeFrom) > today;
    const expired = company.activeTo && today > new Date(company.activeTo);
    return !(notYetActive || expired);
};
export const isDriverBoss = (driverParam) => {
    if (!driverParam)
        return false;
    const { user } = driverParam;
    if (!user)
        return false;
    const roles = user.roles.map((role) => role.name);
    return roles.includes(UserRole.COMPANY);
};
export const getValueBasedOnCompanyType = (type, value, disguise = null) => {
    switch (type) {
        case CompanyTypes.ADAC:
            return disguise;
        default:
            return value;
    }
};
export const disguisedFirstName = 'ADAC';
export const disguisedFamilyName = 'Fahrer';
export const disguiseDriverInfo = (driverInfo, companyType) => {
    if (!driverInfo || !driverInfo.driver)
        return driverInfo;
    const { firstName, familyName, photo } = driverInfo.driver;
    return Object.assign(Object.assign({}, driverInfo), { driver: Object.assign(Object.assign({}, driverInfo.driver), { firstName: getValueBasedOnCompanyType(companyType, firstName, disguisedFirstName) || '', familyName: getValueBasedOnCompanyType(companyType, familyName, disguisedFamilyName) || '', photo: getValueBasedOnCompanyType(companyType, photo) }) });
};
