/* eslint-disable import/no-extraneous-dependencies */

import React from 'react';
import { getResources, showNotification as showNotificationAction } from 'react-admin';
import { connect } from 'react-redux';

import {
  __,
  activeNotificationSettingsWithTwilio,
  CompanyTypes,
  defaultNotificationSettingValues,
  getAllNotificationSettingValues,
  getApiRoutes,
  hasUserRole,
  NotificationSettingsFormValues,
  UserRole,
} from '@adac/core-model';
import {
  Button,
  ColumnLayout,
  ErrorMessage,
  Loader,
  NotificationSettingsForm,
  NotificationSettingsFormSchema,
  saveNotificationSettings,
  useCompanyById,
  useMyNotificationSettings,
  View,
} from '@adac/core-view';
import { Form, Formik, FormikHelpers as FormikActions, FormikProps } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components';
import { useUserRole } from '../../helpers/useUserRole';
import StoresContext from '../../stores/index';
import { Card } from '../cases/CaseStatus';
import Header from '../common/Header';
import { RAShowNotificationProps } from '../common/react-admin/interfaces';
import CardContent from './CardContent';

const ConfirmButtons = styled(View)`
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
`;

interface NotificationSettingsEdit extends RAShowNotificationProps, RouteComponentProps<any> {}

const NotificationSettingsEdit = (raProps: NotificationSettingsEdit) => {
  const stores = React.useContext(StoresContext);
  const companyId = raProps.match.params.companyId;
  const { company } = useCompanyById(companyId);
  const requestUrl = getApiRoutes().companyAdmin.getNotificationSettings(companyId);
  const { settings, isLoading } = useMyNotificationSettings(requestUrl);

  const userRole = useUserRole();
  const isAllowedToEdit = hasUserRole(userRole.names, UserRole.MANAGER);

  const goBack = () => {
    raProps.history.goBack();
  };

  const onSubmit = async (values: any) => {
    try {
      await saveNotificationSettings({ settings: values, companyId }, getApiRoutes().companyAdmin.saveNotificationSettings());
      raProps.history.goBack();
    } catch (error) {
      stores.ui.setOverlay(<ErrorMessage>{__((error as any)?.toLocalizedString() ?? `${error}`)}</ErrorMessage>);
    }
  };

  return (
    <Card>
      <Header goBack={goBack} title={`${__('Notification settings')} (${companyId})`} />
      <CardContent>
        <Loader isLoading={isLoading}>
          <Formik
            initialValues={settings || defaultNotificationSettingValues[company?.type || CompanyTypes.EXT]}
            validationSchema={NotificationSettingsFormSchema}
            enableReinitialize
            validateOnMount
            onSubmit={async (values: NotificationSettingsFormValues, actions: FormikActions<NotificationSettingsFormValues>) => {
              await onSubmit(values);
              actions.setSubmitting(false);
            }}
          >
            {({ isSubmitting, isValid }: FormikProps<NotificationSettingsFormValues>) => (
              <Form>
                <NotificationSettingsForm
                  readonly={!isAllowedToEdit}
                  allSettingValues={getAllNotificationSettingValues(activeNotificationSettingsWithTwilio)}
                  activeSettingValues={activeNotificationSettingsWithTwilio}
                />
                {isAllowedToEdit && (
                  <ConfirmButtons>
                    <ColumnLayout ratio='1fr 1fr' gap='8px'>
                      <Button
                        type='button'
                        info
                        disabled={isSubmitting}
                        onClick={() => raProps.history.goBack()}
                        isLoading={isSubmitting}
                        title={__('Cancel')}
                      />

                      <Button disabled={isSubmitting || !isValid} type='submit' ctaBorder isLoading={isSubmitting} title={__('Save')} />
                    </ColumnLayout>
                  </ConfirmButtons>
                )}
              </Form>
            )}
          </Formik>
        </Loader>
      </CardContent>
    </Card>
  );
};

const mapEditStateToProps = (state: any) => ({
  resources: getResources(state),
});

export default connect(mapEditStateToProps, {
  showNotification: showNotificationAction,
})(withRouter(NotificationSettingsEdit));
