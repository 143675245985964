import { getApiRoutes, ImageReport } from '@adac/core-model';
import { request } from '@adac/core-view';
import { EditReportImageFormValues } from '../components/cases/caseStatus/EditReportModal';

export const getReportImages = async (caseToken: string) => {
  const response = await request<ImageReport>(getApiRoutes().backoffice.case.reportImages(caseToken), {
    method: 'GET',
  });
  return response;
};

export const updateReportImages = async (caseToken: string, data: EditReportImageFormValues) => {
  const response = await request(getApiRoutes().backoffice.case.updateReportImages(caseToken), {
    method: 'POST',
    data,
  });
  return response;
};
