import { List, Datagrid, TextField, showNotification as showNotificationAction, refreshView as refreshViewAction } from 'react-admin';

import { connect } from 'react-redux';
import { __ } from '@adac/core-model';
import UserFilter from './UserFilter';
import { GenericListActions } from '../common/GenericListActions';
import { RAShowNotificationProps } from '../common/react-admin/interfaces';
import { UserUnblockButton } from './UnblockButton';
import { UserBlockAdminLogsButton } from './UserBlockAdminLogsButton';

export interface UserBlockListProps extends RAShowNotificationProps {
  record: { id: number };
  history: {
    push: (route: string) => void;
  };
  refreshView: () => void;
}

export const UserBlockList = (props: UserBlockListProps) => (
  <List {...props} bulkActionButtons={false} actions={<GenericListActions allowCreate={false} />} filters={<UserFilter />}>
    <Datagrid>
      <TextField source='id' label={__('User id')} sortable />
      <TextField source='name' label={__('Name')} sortable />
      <TextField source='username' label={__('Username')} sortable />
      <TextField source='roleName' label={__('Role')} sortable={false} />
      <TextField source='phone' label={__('mfaPhone')} sortable={false} />
      <TextField source='lastLogin' label={__('lastLogin')} sortable />
      <TextField source='passwordUpdatedAt' label={__('passwordUpdatedAt')} sortable />
      <TextField source='updatedAt' label={__('updatedAt')} sortable />
      <UserBlockAdminLogsButton {...props} />
      <UserUnblockButton {...props} />
    </Datagrid>
  </List>
);

export default connect(null, {
  showNotification: showNotificationAction,
  refreshView: refreshViewAction,
})(UserBlockList);
