/* eslint-disable import/no-extraneous-dependencies */

import { useContext } from 'react';
import { showNotification as showNotificationAction } from 'react-admin';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { FormikHelpers as FormikActions } from 'formik';

import {
  Button,
  ButtonTitle,
  changePasswordCommit,
  ChangePasswordFormValues,
  changePasswordStart,
  confirmToken,
  ConfirmTokenForm,
  ErrorConfirmFunc,
  LogoutButton,
  SubTitle,
  Text,
  Title,
  useAsyncComponent,
  useFormikAuth,
  useGetMe,
  useTokenMatcher,
  View,
} from '@adac/core-view';

import { __, AuthenticationMode, AvailableNotificationSettingValues as Channel, SignInChannelOptions } from '@adac/core-model';

import { Card } from '@material-ui/core';
import CustomCard, { CardContent } from '../common/CustomCard';

import { updateSignInUserSetting } from '../../networking/settings';
import StoresContext from '../../stores';
import { SimpleHeader } from '../common/Header';
import { FormProps } from '../common/react-admin/interfaces';
import { ChangePassword } from './ChangePassword';
import { UserMetaInfo } from './UserMetaInfo';
import { SignInChannelFormValues, UserSignInChannelForm } from './UserSignInChannelForm';

const ButtonContainerStyles = styled(CardContent).attrs(() => ({
  as: 'fieldset',
}))``;

const ChannelFormStyles = styled(View)`
  form {
    max-width: 700px;
    margin: auto;
  }
`;

export const LayoutStyles = styled(CustomCard)`
  section {
    padding: 24px;
  }

  fieldset {
    padding: 24px;
    display: grid;
    grid-gap: 10px;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin-top: 40px;
  }
`;

const LogoutButtonStyles = styled(View)`
  margin-top: 40px;
  display: flex;
  justify-content: center;
`;

const Profile = (props: FormProps<null>) => {
  const { tokenKey, tokenValue, setTokenKey, setTokenValue, state } = useTokenMatcher();
  const {
    auth: { deleteToken, userName },
  } = useContext(StoresContext);
  const { user } = useGetMe();
  const goTo = props.history.push;
  const goBack = props.history.goBack;

  const submitChangePassword = async (values: ChangePasswordFormValues, actions: FormikActions<ChangePasswordFormValues>) => {
    try {
      await changePasswordCommit(values.currentPassword, values.newPassword, values.confirmPassword, tokenKey, tokenValue);
      goTo('/', { prefixed: false });
      props.showNotification(__('Password changed'), 'success');
    } catch (error) {
      props.showNotification(__((error as Error)?.message), 'error');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const submitSignInChannelChange = async (values: SignInChannelFormValues, actions: FormikActions<SignInChannelFormValues>) => {
    if (!user || user.id === undefined) {
      props.showNotification(__('user id not found'), 'error');
      return;
    }
    try {
      const payload = {
        settings: {
          [AuthenticationMode.SIGN_IN_CHANNEL]: values.channel as SignInChannelOptions,
        },
        phone: values.channel === Channel.SMS ? values.address : undefined,
      };
      await updateSignInUserSetting(user?.id, payload);
      goTo('/', { prefixed: false });
      props.showNotification(__('The method of sending the verification code has been changed successfully.'), 'success');
    } catch (error) {
      props.showNotification(__((error as Error)?.message), 'error');
    } finally {
      actions.setSubmitting(false);
    }
  };

  const { Component: StartChangeButton } = useAsyncComponent({
    onClick: async () => {
      const token = await changePasswordStart();
      setTokenKey(token);
    },
  });

  const onConfirmError: ErrorConfirmFunc = () => {
    props.showNotification(__('Unsuccessfull error on changing password attempt'), 'error');
  };

  const { onSubmit: onConfirmSubmit } = useFormikAuth(confirmToken, onConfirmError, (token) => setTokenValue(token));

  if (state === 'validated') {
    return (
      <Card>
        <SimpleHeader {...props} title={__('Your Profile')} />
        <ChannelFormStyles>
          <UserSignInChannelForm {...props} onSubmit={submitSignInChannelChange} />
        </ChannelFormStyles>
        <ChangePassword {...props} onSubmit={submitChangePassword} />

        <LogoutButtonStyles>
          <LogoutButton onClick={() => deleteToken()} />
        </LogoutButtonStyles>
      </Card>
    );
  }

  if (state === 'validating') {
    return (
      <LayoutStyles>
        <ConfirmTokenForm tokenKey={tokenKey} onSubmit={onConfirmSubmit} onCancel={() => setTokenKey('')}>
          <Title>
            {__('verificationMessageStart')}
            {userName}
            {__('verificationMessageEnd')}
          </Title>
          <br />
          <br />
          <Text>{__('Please enter confirmation token value')}</Text>
        </ConfirmTokenForm>
      </LayoutStyles>
    );
  }

  return (
    <LayoutStyles>
      <SimpleHeader {...props} title={__('Your Profile')} />
      {user && <UserMetaInfo {...user} />}

      <SubTitle>{__('Click here to change your login details')}</SubTitle>
      <Text>
        {__('verificationMessageStart')}
        {userName}
        {__('verificationMessageEnd')}
      </Text>

      <ButtonContainerStyles>
        <StartChangeButton cta type='submit' title={__('Confirm')} />
        <Button info type='button' onClick={goBack}>
          <ButtonTitle>{__('Cancel')}</ButtonTitle>
        </Button>
      </ButtonContainerStyles>
    </LayoutStyles>
  );
};

export default connect(null, {
  showNotification: showNotificationAction,
})(Profile);
