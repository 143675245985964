import { getCustomerRoutes } from '../networking/routes.js';
import { ProductTypes } from '../types/Case.js';
export const allConsentItems = [
    'serviceTerms',
    'withdrawalServiceStart',
    'withdrawalRightEnds',
    'privacyPolicy',
    'acknowledgeDamage',
    'confirmAuthorization',
    'doorFrameConditions',
    'doorLockConditions',
    'documentedDoorCondition',
];
export const serviceAndPrivacyConsentItems = ['serviceTerms', 'withdrawalServiceStart', 'withdrawalRightEnds', 'privacyPolicy'];
export const damageAndAuthorizationConsentItems = ['acknowledgeDamage', 'confirmAuthorization'];
export const doorConditionConsentItems = ['doorFrameConditions', 'doorLockConditions'];
export const documentedDoorConditionConsentItems = ['documentedDoorCondition'];
export const openingReportConsents = serviceAndPrivacyConsentItems.concat(damageAndAuthorizationConsentItems);
export const extraDamageReportConsents = doorConditionConsentItems.concat(documentedDoorConditionConsentItems);
export const consentItems = {
    [ProductTypes.STANDARD]: [...serviceAndPrivacyConsentItems, ...damageAndAuthorizationConsentItems, ...extraDamageReportConsents],
    [ProductTypes.PREMIUM]: ['privacyPolicy', ...damageAndAuthorizationConsentItems, ...extraDamageReportConsents],
    [ProductTypes.INSURANCE]: [...damageAndAuthorizationConsentItems, ...extraDamageReportConsents],
};
export const serviceTermLinks = {
    [ProductTypes.STANDARD]: `${getCustomerRoutes().staticAsset.pdfRoot}/ASD_Schluesselnotdienst_AGB_PPU_2024_11_12.pdf`,
    [ProductTypes.PREMIUM]: `${getCustomerRoutes().staticAsset.pdfRoot}/ASD_Schluesselnotdienst_AGB_AL_online_12_23.pdf`,
    [ProductTypes.INSURANCE]: `${getCustomerRoutes().staticAsset.pdfRoot}/ASD_Schluesselnotdienst_AGB_AL_online_12_23.pdf`,
};
export const privacyLinks = {
    [ProductTypes.STANDARD]: `${getCustomerRoutes().staticAsset.pdfRoot}/ADAC_Schluesselnotdienst_Datenschutz_PDF_PUB_03_24.pdf`,
    [ProductTypes.PREMIUM]: 'https://www.adac.de/datenschutz/versicherungen/',
    [ProductTypes.INSURANCE]: undefined,
};
