import { parseDate } from '../index.js';
export function calcPrice(value, date) {
    var _a;
    const sorted = value.sort((a, b) => b.validFrom.valueOf() - a.validFrom.valueOf());
    const foundRule = sorted.find(({ validFrom }) => validFrom <= date);
    return (_a = foundRule === null || foundRule === void 0 ? void 0 : foundRule.value) !== null && _a !== void 0 ? _a : sorted[0].value;
}
const priceChanges = {
    2019: parseDate('2019-01-01'),
    2020: parseDate('2020-07-01'),
    2021: parseDate('2021-10-26'),
    2022: parseDate('2022-01-04T00:00:00.000+01:00'),
    2024: parseDate('2024-01-04T00:00:00.000+01:00'),
};
export const priceConfig = {
    WEEKDAY: [
        { validFrom: priceChanges[2019], value: 99 },
        { validFrom: priceChanges[2020], value: 95 },
    ],
    PARTNER_ONSHIFT: [{ validFrom: priceChanges[2019], value: 83.3 }],
    PARTNER_OFFSHIFT: [{ validFrom: priceChanges[2019], value: 107.1 }],
    PARTNER_NIGHT: [{ validFrom: priceChanges[2019], value: 130.9 }],
    OTHER: [
        { validFrom: priceChanges[2019], value: 179 },
        { validFrom: priceChanges[2020], value: 169 },
    ],
    DEFECTIVE: [
        { validFrom: priceChanges[2019], value: 30 },
        { validFrom: priceChanges[2020], value: 29 },
        { validFrom: priceChanges[2021], value: 30 },
    ],
    LOCK: [
        { validFrom: priceChanges[2019], value: 30 },
        { validFrom: priceChanges[2020], value: 29 },
        { validFrom: priceChanges[2021], value: 30 },
    ],
    HARDWARE: [
        { validFrom: priceChanges[2019], value: 65 },
        { validFrom: priceChanges[2020], value: 63 },
        { validFrom: priceChanges[2021], value: 65 },
        { validFrom: priceChanges[2022], value: 0 },
    ],
    INCIDENTAL_ITEMS: [
        { validFrom: priceChanges[2019], value: 0 },
        { validFrom: priceChanges[2021], value: 5 },
    ],
    SECURITY_FITTING: [{ validFrom: priceChanges[2024], value: 65 }],
    OTHER_EXTRA: [{ validFrom: priceChanges[2022], value: 10 }],
    MILLING: [
        { validFrom: priceChanges[2019], value: 0 },
        { validFrom: priceChanges[2021], value: 10 },
    ],
    SNDFEE: [
        { validFrom: priceChanges[2019], value: 20 },
        { validFrom: priceChanges[2020], value: 19 },
        { validFrom: priceChanges[2021], value: 20 },
    ],
    CANCELFEE: [
        { validFrom: priceChanges[2019], value: 0 },
        { validFrom: priceChanges[2021], value: 30 },
    ],
};
export function getPrices(date = new Date(Date.now())) {
    return Object.entries(priceConfig).reduce((prices, [key, configs]) => (Object.assign(Object.assign({}, prices), { [key]: calcPrice(configs, date) })), {});
}
