var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { HTTP_TOO_MANY_REQUESTS } from '../errors/errorCodes.js';
import { isLocalStorageAvailable, loadFromLocalStorage } from '../helpers/localStorage.js';
import { NetworkError } from '../types/NetworkError.js';
axios.defaults.headers.common['snd-app-version'] = `${process.env.REACT_APP_VERSION || process.env.VERSION}`;
function sleepOnAttempt(attempt, unit = 1000) {
    return __awaiter(this, void 0, void 0, function* () {
        yield new Promise((resolve) => {
            setTimeout(resolve, unit * attempt * 1.3);
        });
    });
}
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
function getToken() {
    if (!isLocalStorageAvailable())
        return null;
    const token = loadFromLocalStorage(ACCESS_TOKEN);
    if (!token)
        return null;
    return `Bearer ${token}`;
}
export function request(url, { createCustomError, method = 'GET', timeout = 0, retryAttempts = 0, headers, data }) {
    return __awaiter(this, void 0, void 0, function* () {
        let attempt = 0;
        try {
            if (retryAttempts) {
                axios.interceptors.response.use((value) => value, (error) => __awaiter(this, void 0, void 0, function* () {
                    var _a, _b;
                    if (error.config && ((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) === HTTP_TOO_MANY_REQUESTS && attempt < retryAttempts) {
                        console.log('retry', attempt, 'on status', (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.status, 'at', new Date());
                        attempt += 1;
                        yield sleepOnAttempt(attempt);
                        return axios.request(error.config);
                    }
                    return Promise.reject(error);
                }));
            }
            const response = yield axios.request({
                url,
                method,
                headers: Object.assign({ Authorization: getToken() }, headers),
                data,
                timeout,
            });
            return response.data;
        }
        catch (error) {
            throw createCustomError(error, url, method);
        }
    });
}
export function getListWithHeaders(url, token) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const resources = response.data;
            if (resources.length < 1)
                return { data: resources, total: resources.length };
            const contentRange = response.headers['content-range'];
            if (!contentRange) {
                throw new Error('Missing content-range header');
            }
            const total = parseInt(contentRange.split('/')[1], 10);
            if (!total || isNaN(total)) {
                throw new Error('Invalid content-range header');
            }
            return { data: resources, total };
        }
        catch (error) {
            const newError = new NetworkError(error.response);
            newError.originalError = error;
            throw newError;
        }
    });
}
