import { List, Datagrid, TextField, GET_ONE, FunctionField } from 'react-admin';
import { __, DatabaseRecordTimestamps, config } from '@adac/core-model';
import { useAsyncAction, View } from '@adac/core-view';
import Header from '../common/Header';
import { useRouterParamCompanyId } from '../../helpers/useRouterParam';
import { SND_COMPANIES } from '../../dataProvider/resources';
import dataProvider from '../../dataProvider';
import { EditButtonWithRole } from '../common/react-admin/EditButtonWithRole';
import { useIsUserReadOnly } from '../../helpers/useUserRole';
import { GenericListActions } from '../common/GenericListActions';
import { CustomPagination } from '../cases/CustomPagination';

const DriverList = (props: any) => {
  const companyId = Number(useRouterParamCompanyId(props));

  const company = useAsyncAction(async () => dataProvider(GET_ONE, SND_COMPANIES, { id: companyId }));
  const readonly = useIsUserReadOnly();

  return (
    <View>
      <Header
        goBack={() => props.history.push(`/${SND_COMPANIES}`)}
        title={`${__('Drivers for')}  ${company.resource?.data.name || ''} #${companyId}`}
      />
      <List
        {...props}
        bulkActionButtons={false}
        actions={<GenericListActions basePath={props.basePath?.replace(':companyId', `${companyId}`)} allowCreate />}
        hasCreate={!readonly}
        filterDefaultValues={{
          companyId,
        }}
        pagination={<CustomPagination {...({} as any)} />}
      >
        <Datagrid>
          <TextField source='id' label={__('ID')} sortable />
          <TextField source='firstName' label={__('First Name')} sortable />
          <TextField source='familyName' label={__('Last Name')} sortable />
          <TextField source='privatePhone' label={__('Private phone')} sortable />
          <TextField source='publicPhone' label={__('Public phone')} sortable />
          <TextField source='role' label={__('Role')} sortable />
          <EditButtonWithRole label={__('Edit')} />
          <FunctionField label={__('createdAt')} render={(record: DatabaseRecordTimestamps) => config.time(record.createdAt)} sortable />
          ;
          <FunctionField label={__('updatedAt')} render={(record: DatabaseRecordTimestamps) => config.time(record.updatedAt)} />;
        </Datagrid>
      </List>
    </View>
  );
};
export default DriverList;
