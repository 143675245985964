import { __, config, getApiRoutes, RegionRecord } from '@adac/core-model';
import { request, useAsyncAction } from '@adac/core-view';

const { apiTimeout } = config.location();

// NOTE: this function is used with useAsyncAction, so try-catch is handled in that
async function getRegionList() {
  const url = getApiRoutes().backoffice.region.list;
  return request<RegionRecord[]>(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: apiTimeout,
  });
}

export function useRegionList() {
  const { resource, ...result } = useAsyncAction(getRegionList);
  return {
    ...result,
    regions: [{ id: null, name: __('ALL REGION'), zips: [] }, ...(resource || [])] as RegionRecord[],
  };
}
