import { Form, Field, FieldProps, useFormikContext } from 'formik';
import * as yup from 'yup';

import { TextField, Button, ColumnLayout, FullWidthLayout, Children } from '@adac/core-view';

import { __ } from '@adac/core-model';

import Header from '../common/Header';

import { HolidayFormValues } from './HolidayFormValues';
import { FormStyles, FormLayout } from '../common/FormStyles';
import { MultipleRegionSelector } from './RegionSelector';

export const HolidaySchema = yup.object().shape({
  day: yup.number().required(),
  month: yup.number().required(),
  year: yup.number().notRequired().nullable(),
  name: yup.string().notRequired().nullable(),
  regionNames: yup.array(),
});

interface HolidayFormTypes {
  holiday: HolidayFormValues;
  children?: Children;
  regionSelectorComponent?: JSX.Element;
  onCancel: () => void;
}

export const HolidayForm = ({
  children,
  holiday,
  onCancel,
  regionSelectorComponent: RegionSelectorComponent = <MultipleRegionSelector />,
}: HolidayFormTypes) => {
  const { isSubmitting, isValid } = useFormikContext<HolidayFormValues>();

  return (
    <Form>
      <Header goBack={onCancel} title={holiday?.name || __('Holiday')} id={holiday?.id} />
      <FormStyles>
        <FormLayout>
          <ColumnLayout ratio='1fr 1fr' gap='20px'>
            {holiday?.id && (
              <Field
                name='id'
                render={({ field }: FieldProps<'id', HolidayFormValues>) => (
                  <TextField {...field} disabled badgeEqualsPlaceholder badgeTitle={__('Holiday Id')} />
                )}
              />
            )}
            {!holiday?.id && <span />}

            <Field
              name='name'
              render={({ field }: FieldProps<'name', HolidayFormValues>) => <TextField {...field} badgeEqualsPlaceholder badgeTitle={__('Name')} />}
            />
            <Field
              name='day'
              render={({ field }: FieldProps<'day', HolidayFormValues>) => <TextField {...field} badgeEqualsPlaceholder badgeTitle={__('Day')} />}
            />
            <Field
              name='month'
              render={({ field }: FieldProps<'month', HolidayFormValues>) => <TextField {...field} badgeEqualsPlaceholder badgeTitle={__('Month')} />}
            />
            <Field
              name='year'
              render={({ field }: FieldProps<'year', HolidayFormValues>) => (
                <TextField {...field} badgeEqualsPlaceholder badgeTitle={__('Year (if left empty, yearly holiday)')} />
              )}
            />

            {RegionSelectorComponent}
          </ColumnLayout>

          <ColumnLayout ratio='1fr 1fr'>
            <FullWidthLayout style={{ width: 'auto' }}>
              <Button type='button' info disabled={isSubmitting} onClick={() => onCancel()} isLoading={isSubmitting} title={__('Cancel')} />
            </FullWidthLayout>

            <FullWidthLayout>
              <Button type='submit' cta disabled={isSubmitting || !isValid} isLoading={isSubmitting} title={__('Update')} />
            </FullWidthLayout>
          </ColumnLayout>

          {children && <ColumnLayout ratio='1fr'>{children}</ColumnLayout>}
        </FormLayout>
      </FormStyles>
    </Form>
  );
};
