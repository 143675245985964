/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import { Admin, Layout, Resource, showNotification as showNotificationAction } from 'react-admin';

import { View } from '@adac/core-view';
import { ThemeProvider } from '@material-ui/styles';
import { Route } from 'react-router';

import { observer } from 'mobx-react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  ADMIN_LOGS,
  BENCHMARK,
  CLOSED_CASES,
  HOLIDAYS,
  OPEN_CASES,
  SND_COMPANIES,
  SND_COMPANY_DRIVERS,
  USERS_BACKOFFICE,
  USERS_BLOCKED,
} from '../../dataProvider/resources';

import Closing from '../cancellation/Closing';

import setClosingCaseAction from '../../actions/setClosingCase';
import theme from '../../theme/materialTheme';

import ADACAppBar from './ADACAppBar';
import ADACMenu from './ADACMenu';

import dataProvider from '../../dataProvider';

import CompanyCreate from '../snd/CompanyCreate';
import CompanyEdit from '../snd/CompanyEdit';
import CompanyList from '../snd/CompanyList';

import UserBlockList from '../users/UserBlockList';
import UserCreate from '../users/UserCreate';
import UserEdit from '../users/UserEdit';
import UserList from '../users/UserList';

import ClosedCaseList from '../cases/ClosedCaseList';
import OpenCaseList from '../cases/OpenCaseList';

import HolidayCreate from '../holiday/HolidayCreate';
import HolidayEdit from '../holiday/HolidayEdit';
import HolidayList from '../holiday/HolidayList';

import AdminLogList from '../adminLog/AdminLogList';
import StatisticsPage from '../statistics/StatisticsPage';

import CaseStatus from '../cases/CaseStatus';
import NewCase from '../cases/NewCase';
import NewCaseDone from '../cases/NewCaseDone';
import Offer from '../cases/NewCasePreview';

import { customerDataReducer } from '../../reducers/customerDataReducer';

import Profile from '../users/Profile';

import blockedUsersReducer from '../../reducers/blockedUsersReducer';
import closingCaseReducer from '../../reducers/closingCaseReducer';
import AdminLogDetails from '../adminLog/AdminLogDetails';
import DriverCreate from '../snd/DriverCreate';
import DriverEdit from '../snd/DriverEdit';
import DriverList from '../snd/DriverList';
import NotificationSettingsEdit from '../snd/NotificationSettingsEdit';
import UserBlockListener from '../users/UserBlockListener';

// NOTE: should be fixing better this for https://trello.com/c/AyBa6UfS/1969-fix-company-driver-edit-form-on-adac-sit-staging-server
const DirtyMUILayoutFix = styled(View)`
  & main > * {
    position: relative !important;
    opacity: 1 !important;
    padding: 24px !important;
    padding-left: 5px !important;
    height: max-height !important;
  }
`;

const ADACLayout = (props: any) => (
  <DirtyMUILayoutFix>
    <Layout
      {...props}
      appBar={ADACAppBar}
      menu={ADACMenu}
      style={{
        maxWidth: 'unset',
      }}
    />
  </DirtyMUILayoutFix>
);

const ClosingPopupForm = () => {
  const mapStateToProps = (state: any) => ({
    on: !!state.closingCase,
    currentCase: state.closingCase,
  });

  const actionsToMapToProps = {
    setClosingCase: setClosingCaseAction,
    showNotification: showNotificationAction,
  };

  const ConnectedClosing = connect(mapStateToProps, actionsToMapToProps)(Closing);
  return <ConnectedClosing />;
};

const renderDevelopmentCaseRoutes = () =>
  process.env.NODE_ENV === 'development' || process.env.REACT_APP_SERVER_ENV === 'staging'
    ? [
        // TODO: we need these components later for staging & development, so we need a better identification.
        <Route path='/newcase' component={NewCase} exact />,
        <Route path='/newcase/offer' component={Offer} exact />,
        <Route path='/newcase/done' component={NewCaseDone} exact />,
      ]
    : [];

export default observer(() => (
  <ThemeProvider
    theme={{
      ...theme,
      overrides: {
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: theme.palette.secondary.main,
          },
        },
      },
    }}
  >
    <Admin
      theme={theme}
      appLayout={ADACLayout}
      dataProvider={dataProvider}
      customRoutes={[
        ...renderDevelopmentCaseRoutes(),
        <Route path='/profile' component={Profile} exact />,
        <Route path='/case-status/:caseId' component={CaseStatus} exact />,
        <Route path='/admin/backoffice/snd-companies/notifications/:companyId' component={NotificationSettingsEdit} exact />,
      ]}
      customReducers={{
        customerData: customerDataReducer,
        closingCase: closingCaseReducer,
        [USERS_BLOCKED]: blockedUsersReducer,
      }}
    >
      <Resource name={OPEN_CASES} list={OpenCaseList} />
      <Resource name={CLOSED_CASES} list={ClosedCaseList} />
      <Resource name={SND_COMPANY_DRIVERS} list={DriverList} edit={DriverEdit} create={DriverCreate} />
      <Resource name={SND_COMPANIES} list={CompanyList} create={CompanyCreate} edit={CompanyEdit} />
      <Resource name={HOLIDAYS} list={HolidayList} create={HolidayCreate} edit={HolidayEdit} />
      <ClosingPopupForm />
      <Resource name={USERS_BACKOFFICE} list={UserList} edit={UserEdit} create={UserCreate} />
      <Resource name={USERS_BLOCKED} list={UserBlockList} />
      <UserBlockListener />
      <Resource name={BENCHMARK} list={StatisticsPage} />
      <Resource name={ADMIN_LOGS} list={AdminLogList} show={AdminLogDetails} />
    </Admin>
  </ThemeProvider>
));
