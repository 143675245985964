import { useContext } from 'react';
import styled from 'styled-components';

import AccountCircle from '@material-ui/icons/AccountCircle';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { View, Button, LogoMobile, LogoOpenhouse, LogoutButton } from '@adac/core-view';

// eslint-disable-next-line import/no-extraneous-dependencies
import { withRouter } from 'react-router';
import StoresContext from '../../stores/index';
import theme from '../../theme/materialTheme';

const Icons = styled(View)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const LeftIcons = styled(View)`
  display: flex;
  align-items: center;
`;

const RightIcons = styled(View)`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ADACAppBar = ({ history }: any) => {
  const stores = useContext(StoresContext);

  return (
    <AppBar position='static' style={{ backgroundColor: theme.palette.secondary.main }}>
      <Toolbar variant='dense'>
        <Icons>
          <LeftIcons>
            <LogoMobile />
            <LogoOpenhouse />
          </LeftIcons>
          <RightIcons>
            <LogoutButton onClick={() => stores.auth.deleteToken()} text='' color={theme.palette.primary.main} />
            <Button onClick={() => history.push('/profile')}>
              <AccountCircle color='primary' />
            </Button>
          </RightIcons>
        </Icons>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(ADACAppBar);
