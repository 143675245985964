import { AuthenticationMode, AvailableNotificationSettingValues as Channel, SignInChannelOptions, getApiRoutes } from '@adac/core-model';
import { request } from '@adac/core-view';

interface AuthNotificationFields {
  [AuthenticationMode.SIGN_IN_CHANNEL]: Channel.SMS | Channel.EMAIL;
}

export const getSignInUserSetting = async (userId: number) => {
  const url = getApiRoutes().admin.settings.notification.getSignInUserSetting(userId);
  const res = await request<AuthNotificationFields>(url, {
    method: 'GET',
  });
  return res;
};

interface UpdateUserSetting {
  settings: {
    [AuthenticationMode.SIGN_IN_CHANNEL]: SignInChannelOptions;
  };
  phone?: string;
}

export const updateSignInUserSetting = async (userId: number, data: UpdateUserSetting) => {
  const url = getApiRoutes().admin.settings.notification.updateSignInUserSetting(userId);
  await request<void, UpdateUserSetting>(url, {
    method: 'PUT',
    data,
  });
};
