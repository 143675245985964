import React from 'react';
import { Datagrid, DatagridBody, List, TextField } from 'react-admin';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withRouter } from 'react-router';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { GetApp as DownloadIcon } from '@material-ui/icons';

import { __, FinalStatusReport } from '@adac/core-model';
import { Icon } from '@adac/core-view';

import ListAction from '../common/ListAction';
import ClosedCaseFilter from './ClosedCaseFilter';
import { CustomPagination } from './CustomPagination';

const ClosedCasesDatagridRow = (props: any) => {
  const { record, resource, id, children, basePath, history } = props;

  const openCasePage = (caseId: string) => {
    history.push(`/case-status/${caseId}`);
  };

  const rowHeight = 60;

  const hasAnyDocumentOrCaseIsCancelled =
    record.cancelled !== __('yes') || record.invoiceDocument || record.commissionReport || record.commissionExtraDamage || record.finalStatusReport;

  return (
    <TableRow key={id} style={{ height: `${rowHeight}px`, cursor: 'pointer' }} hover>
      {/* data columns based on children */}
      {React.Children.map(children, (field) => (
        <TableCell key={`${id}-${field.props.source}`} onClick={() => openCasePage(record.token)} style={{ padding: '8px', textAlign: 'center' }}>
          {React.cloneElement(field, {
            record,
            basePath,
            resource,
          })}
        </TableCell>
      ))}
      <TableCell padding='default' style={{ position: 'relative' }}>
        {hasAnyDocumentOrCaseIsCancelled && ( // CANCELLED only shall show invoice.
          <ListAction
            id={id}
            rowHeight={rowHeight}
            icon={<Icon name='document' />}
            items={[
              {
                title: __('Assignment'),
                fileDownloadLink: record.commissionReport,
                visible: !!record.commissionReport,
                icon: <DownloadIcon />,
              },
              {
                title: __('Authorization to destroy'),
                fileDownloadLink: record.commissionExtraDamage,
                visible: !!record.commissionExtraDamage, // TODO: commissionExtraDamage is never retrieved
                icon: <DownloadIcon />,
              },
              ...(record.finalStatusReport
                ? [
                    {
                      title: `${__('Progress report')} ${__('For Customer')}`,
                      fileDownloadLink: (record.finalStatusReport as FinalStatusReport).customer,
                      visible: !!(record.finalStatusReport as FinalStatusReport).customer,
                      icon: <DownloadIcon />,
                    },
                    {
                      title: `${__('Progress report')} ${__('For Billing')}`,
                      fileDownloadLink: (record.finalStatusReport as FinalStatusReport).billing,
                      visible: !!(record.finalStatusReport as FinalStatusReport).billing,
                      icon: <DownloadIcon />,
                    },
                    {
                      title: `${__('Progress report')} ${__('For Partner')}`,
                      fileDownloadLink: (record.finalStatusReport as FinalStatusReport).partner,
                      visible: !!(record.finalStatusReport as FinalStatusReport).partner,
                      icon: <DownloadIcon />,
                    },
                  ]
                : []),
              {
                title: __('Rechnung'),
                fileDownloadLink: record.invoiceDocument?.fileUrl,
                visible: !!record.invoiceDocument,
                icon: <Icon name='invoice' />,
              },
            ]}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

const ClosedCasesDatagridBody = (props: any) => {
  const { history } = props;
  return <DatagridBody {...props} row={<ClosedCasesDatagridRow history={history} />} />;
};
const ClosedCasesDatagrid = (props: any) => <Datagrid {...props} body={<ClosedCasesDatagridBody history={props.history} />} />;

export default withRouter((props: any) => {
  const { history } = props;
  // bulkActionButtons contains stuff like the dlete checkbox
  // actions contains the export csv button and functionality

  return (
    <List
      classes={{ header: { background: 'yellow' } }}
      {...props}
      bulkActionButtons={false}
      filters={<ClosedCaseFilter sndCompany />}
      pagination={<CustomPagination {...({} as any)} />}
    >
      <ClosedCasesDatagrid history={history}>
        <TextField source='id' label={__('Case-Id')} /* sortable={false} */ />
        <TextField source='commissioningReference' label={__('CommissioningReference')} /* sortable={false} */ />
        <TextField source='customerName' label={__('Name of customer')} /* sortable={false} */ />
        <TextField source='customerPhone' label={__('Customer Phone')} /* sortable={false} */ />
        <TextField source='productType' label={__('ProductType')} />
        <TextField source='customerAddress' label={__('Customer Address')} /* sortable={false} */ />
        <TextField source='customerZipCode' label={__('Zip Code')} /* sortable={false} */ />
        <TextField source='companyName' label={__('SND Name')} sortable={false} />
        <TextField source='complexity' label={__('esd')} sortable />
        <TextField source='timeOfOrder' label={__('Time of Order')} sortable />
        <TextField source='scheduledTs' label={__('Appointment')} sortable={false} />
        <TextField source='drivingTime' label={__('Driving time')} sortable={false} />
        <TextField source='lastSignature' label={__('Last signature')} sortable={false} />
        <TextField source='cancellation' label={__('Cancelled')} sortable={false} />
        <TextField source='price' label={__('Price')} /* sortable={false} */ />

        {/* <TextField source="companyId" label={__('SND ID')} /> */}
        {/* // no companyName on Case model */}
        {/* // no driverId on Case model */}
        {/* <TextField source="driverId" label={__('Driver ID')} sortable={false} /> */}
        {/* // no driverName on Case model */}
        {/* <TextField source="driverName" label={__('Driver Name')} sortable={false} /> */}
        {/* <TextField source="token" label={__('Token')} /> */}
        {/* // customerName is created on frontend from two names from backend */}
        {/* // no firstSignature on Case model */}
        {/* <TextField source="firstSignature" label={__('First signature')} sortable={false} /> */}
        {/* // no lastSignature on Case model */}
        {/* // no cancelled on Case model */}
        {/* // no price on Case model */}
        {/* <TextField source="token" label={__('Rechnungsnum.')}/> */}

        {/* // no customerZipCode on Case model */}
      </ClosedCasesDatagrid>
    </List>
  );
});
