import { useState } from 'react';

import { Button, ChildrenProps } from '@adac/core-view';

import ListActionPopup, { ListActionPopupProps, ListActionItemComponent } from './ListActionPopup';

interface ButtonProps extends ListActionPopupProps, ChildrenProps {
  id: number;
  icon: JSX.Element;
}

const ListActionPopupButton = ({ id, rowHeight, items, icon, ...props }: ButtonProps) => {
  const [showActionsForId, setShowActionsForId] = useState<number | null>(null);

  const onActionsClicked = (idClicked: number) => {
    setShowActionsForId(showActionsForId === idClicked ? null : idClicked);
  };

  return (
    <Button onClick={() => onActionsClicked(id)}>
      <>
        {icon}
        {showActionsForId === id && <ListActionPopup rowHeight={rowHeight} items={items} {...props} />}
      </>
    </Button>
  );
};

export default (props: ButtonProps) => {
  const { items } = props;
  const visibleItems = items.filter((item) => item.visible);

  if (visibleItems.length === 1) return <ListActionItemComponent {...visibleItems[0]} />;
  if (visibleItems.length > 1) return <ListActionPopupButton {...props} />;
  return null;
};
