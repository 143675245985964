/* eslint-disable import/no-extraneous-dependencies */

import styled from 'styled-components';
import { Formik, Form, Field, FieldProps, FormikProps } from 'formik';
import * as yup from 'yup';

import {
  View,
  ButtonTitle,
  TextField,
  Title,
  CheckboxItem,
  CheckBoxGroup,
  ColumnLayout,
  SubTitle,
  CheckBoxItem,
  ErrorMessage,
  SmallText,
  RadioGroup,
  SimpleCheckMark,
  Modal,
  Button,
} from '@adac/core-view';
import { __, EscalationData, JobCancelation, toggleArrayItem } from '@adac/core-model';

import { closeCase } from '../../dataProvider/closing';
import { CaseCloseType } from '../../actions/setClosingCase';

const ClosingStyles = styled(View)`
  width: 100%;
  padding: 16px;
  & > * {
    width: 100%;
    margin: 16px 0;
  }
`;

const ButtonStyles = styled(Button)`
  width: 33%;
  display: block;
  margin: 0 auto;
`;

const isOther = (reasons: string[]) => reasons.includes('other');

const ClosingSchema = yup.object().shape({
  allowInvoiceUpload: yup.boolean().required(),
  reasons: yup.array(yup.string().required()).min(1),
  otherReason: yup.string().when('reasons', (reasons: string[]) => (isOther(reasons) ? yup.string().min(1).required() : yup.string().notRequired())),
});

interface ClosingFormValues {
  allowInvoiceUpload: boolean | undefined;
  reasons: string[];
  otherReason: string;
}

interface ClosingProps {
  on: boolean;
  setClosingCase: (closing: CaseCloseType) => void;
  currentCase: EscalationData;
  showNotification: (error: string) => void;
}

const initialValues = {
  allowInvoiceUpload: undefined,
  reasons: [],
  otherReason: '',
};

const reasonItems = ['noInternet', 'systemError', 'ownInitiative', 'other'];

export default ({ on, setClosingCase, currentCase, showNotification }: ClosingProps) => {
  const onSubmit = async (values: JobCancelation) => {
    try {
      await closeCase(currentCase, values);
      setClosingCase(false);
    } catch (error) {
      showNotification(error?.toString() ?? `${error}`);
    }
  };

  return (
    <Modal on={on} close={() => setClosingCase(null)}>
      <Formik
        initialValues={initialValues}
        validationSchema={ClosingSchema}
        onSubmit={async ({ allowInvoiceUpload, reasons, otherReason }, actions) => {
          await onSubmit({
            allowInvoiceUpload,
            reason: reasons.reduce((translated, item) => `${translated}${item === 'other' ? otherReason : item}`, ''),
          });
          actions.setSubmitting(false);
        }}
      >
        {({ values, setFieldValue, isSubmitting, isValid, errors }: FormikProps<ClosingFormValues>) => {
          const itemsWithControl = reasonItems.map(
            (item) =>
              ({
                id: item,
                value: item,
                labelTitle: __(`form:label:${item}`),
                checked: !!values.reasons.includes(item),
                setChecked: () => setFieldValue('reasons', toggleArrayItem(values.reasons, item)),
              }) as CheckBoxItem
          );

          return (
            <Form>
              <ClosingStyles>
                <Title>{__('Close case')}</Title>

                <SubTitle>{__('Has the case already been regulated?')}</SubTitle>

                {errors.allowInvoiceUpload && <ErrorMessage>{__(errors.allowInvoiceUpload)}</ErrorMessage>}

                <Field
                  id='allowInvoiceUpload'
                  name='title'
                  render={() => (
                    <RadioGroup
                      renderItem={SimpleCheckMark}
                      items={[
                        {
                          value: 'false',
                          title: __('yes'),
                          id: 'false',
                        },
                        {
                          value: 'true',
                          title: __('no'),
                          id: 'true',
                        },
                      ]}
                      value={String(values.allowInvoiceUpload) || ''}
                      name='allowInvoiceUpload'
                      onChange={(value: string) => setFieldValue('allowInvoiceUpload', value === 'true')}
                    />
                  )}
                />

                <SubTitle
                  style={{
                    display: 'flex',
                    alignItems: 'baseline',
                    gap: '10px',
                  }}
                >
                  {__('What is the reason for closing this case manually?')}
                  <SmallText tiny>({__('Multiple answers possible')})</SmallText>
                </SubTitle>

                <Field
                  name='reason'
                  render={({ field }: FieldProps<'reason', JobCancelation>) => (
                    <CheckBoxGroup {...field} items={itemsWithControl} renderItem={CheckboxItem} />
                  )}
                />
                {isOther(values.reasons) && (
                  <Field
                    name='otherReason'
                    render={({ field }: FieldProps<'otherReason', JobCancelation>) => <TextField isValid={!errors.otherReason} {...field} />}
                  />
                )}
                {errors.otherReason && <ErrorMessage>{__(errors.otherReason)}</ErrorMessage>}

                <ColumnLayout ratio='repeat(2, 1fr)'>
                  <ButtonStyles
                    type='submit'
                    cta
                    disabled={!isValid || isSubmitting}
                    isLoading={isSubmitting}
                    style={{ width: '100%' }}
                    title={__('Close this case definitively')}
                  />

                  <ButtonStyles type='button' cancel onClick={() => setClosingCase(false)} style={{ width: '100%' }}>
                    <ButtonTitle>{__('Cancel')}</ButtonTitle>
                  </ButtonStyles>
                </ColumnLayout>
              </ClosingStyles>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
