import { ColumnLayout, Button, ButtonTitle } from '@adac/core-view';

interface NewCaseButtonAreaProps {
  cancel: () => void;
  disabled: boolean;
  isLoading?: boolean;
  firstButtonText?: string;
  secondButtonText?: string;
}

export default ({ cancel, disabled, isLoading, firstButtonText, secondButtonText }: NewCaseButtonAreaProps) => (
  <ColumnLayout ratio='repeat(2, 1fr)'>
    <Button info onClick={cancel}>
      <ButtonTitle>{firstButtonText || 'Stornieren'}</ButtonTitle>
    </Button>
    <Button cta type='submit' title={secondButtonText || 'Weiter'} disabled={disabled} isLoading={isLoading || false} />
  </ColumnLayout>
);
