import { Formik, Form, FormikProps, Field, FieldProps } from 'formik';
import * as yup from 'yup';

import styled from 'styled-components';

import { ColumnLayout } from '@adac/core-view';

import { DatePickerInput, DatePickerUtilsProvider } from '../common/DatePickerInput';

import { FormStyles, FormLayout } from '../common/FormStyles';
import { convertStringToDate } from '../../helpers/date';

const DateFilterFormStyles = styled(FormStyles)`
  width: max-content;
  & > * {
    min-width: unset;
    width: 100%;
    max-width: 330px;
  }
`;

const DateFilterFormLayout = styled(FormLayout)`
  & > * {
    margin: 24px 0;
  }
`;

export const DateFilterSchema = yup.object({
  from: yup.date().nullable(),
  to: yup.date().nullable(),
});

export interface DateFilterProps {
  from?: Date;
  to?: Date;
}

interface DateFilterFormProps extends DateFilterProps {
  onSubmit?: (values: DateFilterProps) => void;
  onChange?: (values: DateFilterProps) => void;
}

export const DateFilterForm = ({ onSubmit, onChange, ...initialValues }: DateFilterFormProps) => (
  <Formik
    initialValues={initialValues}
    validationSchema={DateFilterSchema}
    onSubmit={(values) => {
      if (onSubmit) onSubmit(values);
    }}
  >
    {({ setFieldValue, values }: FormikProps<DateFilterProps>) => {
      const onFieldChange = (field: string, value: Date | null) => {
        setFieldValue(field, value);
        if (onChange) {
          onChange({ ...values, [field]: value });
        }
      };
      return (
        <Form>
          <DatePickerUtilsProvider>
            <DateFilterFormStyles>
              <DateFilterFormLayout>
                <ColumnLayout ratio='1fr 1fr' gap='20px' margin='0'>
                  <Field
                    name='from'
                    render={({ field }: FieldProps<'from', DateFilterProps>) => (
                      <DatePickerInput
                        placeHolder='From'
                        value={convertStringToDate(field.value)}
                        format='date'
                        onChange={(value) => onFieldChange('from', value)}
                      />
                    )}
                  />
                  <Field
                    name='to'
                    render={({ field }: FieldProps<'to', DateFilterProps>) => (
                      <DatePickerInput
                        placeHolder='To'
                        value={convertStringToDate(field.value)}
                        format='date'
                        onChange={(value) => onFieldChange('to', value)}
                      />
                    )}
                  />
                </ColumnLayout>
              </DateFilterFormLayout>
            </DateFilterFormStyles>
          </DatePickerUtilsProvider>
        </Form>
      );
    }}
  </Formik>
);
