import { __, ImageReport } from '@adac/core-model';
import { useCallback, useMemo } from 'react';
import { concatSchemas } from '@adac/core-view';
import * as yup from 'yup';
import { EditReportImageFormValues } from '../components/cases/caseStatus/EditReportModal';

const defaultEditReportImageFormValues: EditReportImageFormValues = {
  comissionOpening: {
    photos: [false],
    signature: false,
  },
  reason: [],
};

const SignatureAndPhotosSchema = yup.object().shape({
  photos: yup.array(yup.boolean()).required().min(1),
  signature: yup.boolean().required(),
});

const CommissionExtraDamageEditSchema = yup.object().shape({
  commissionExtraDamage: SignatureAndPhotosSchema,
});

const OpeningCompletedEditSchema = yup.object().shape({
  openingCompleted: SignatureAndPhotosSchema,
});

const ReasonSchema = yup.array().of(yup.string()).min(1).required();

const OtherReasonSchema = yup.string().when('reason', (reason, schema) =>
  reason[0].includes('other')
    ? schema
        .trim()
        .test('len', `${__('Reason must be more than 10 characters')}`, (val) => !!val && val.length > 10)
        .required()
    : schema.optional()
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EditImageReportSchema = (schemas: yup.ObjectSchema<any>[]) =>
  concatSchemas(...schemas).shape({
    comissionOpening: SignatureAndPhotosSchema,
    reason: ReasonSchema,
    otherReason: OtherReasonSchema,
  });

export function useImageReportFormikProps(reports?: ImageReport) {
  const buildInitialValues = useCallback((): EditReportImageFormValues => {
    if (!reports) {
      return defaultEditReportImageFormValues;
    }
    return Object.entries(reports).reduce(
      (acc, [reportType, { photos: originalPhotos }]) => ({
        ...acc,
        [reportType]: {
          photos: originalPhotos.map(() => false),
          signature: false,
        },
      }),
      defaultEditReportImageFormValues
    );
  }, [reports]);

  return {
    initialValues: useMemo(() => buildInitialValues(), [buildInitialValues]),
    validationSchema: EditImageReportSchema([
      reports?.commissionExtraDamage ? CommissionExtraDamageEditSchema : yup.object({}),
      reports?.openingCompleted ? OpeningCompletedEditSchema : yup.object({}),
    ]),
  };
}
