/* eslint-disable import/no-extraneous-dependencies */

import styled from 'styled-components';

import { Title, Text, View, Button, ButtonTitle } from '@adac/core-view';
import { __ } from '@adac/core-model';

import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { SimpleHeader } from '../common/Header';
import CustomCard, { CardContent } from '../common/CustomCard';
import UserDataList from './UserDataList';
import { useClearCaseOnLeavingPage } from '../../helpers/customerData';
import saveManualCase from '../../actions/saveManualCase';
import { OPEN_CASES } from '../../dataProvider/resources';

const CaseTitleArea = styled(View)`
  display: grid;
  place-items: center;

  text-align: center;

  margin-bottom: 24px;

  & > * {
    margin: 8px 0;
  }
`;

const CaseId = styled(View)`
  display: flex;
`;

const CaseIdLabel = styled(Text)`
  font-size: 1.6rem;
  margin-right: 4px;
`;

const CaseIdText = styled(Text)`
  font-size: 1.6rem;
  font-weight: bold;
`;

const InformationTitle = styled(Text)`
  font-weight: bold;
`;

const ButtonStyles = styled(View)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  place-items: center;

  & > * {
    width: 100%;
  }
`;

const NewCaseDone = (props: any) => {
  useClearCaseOnLeavingPage(props.history, props.saveCustomerData);

  if (!props.customerData) {
    return (
      <Redirect
        to={{
          pathname: `/${OPEN_CASES}`,
        }}
      />
    );
  }

  return (
    <CustomCard>
      <SimpleHeader {...props} title={__('Order Confirmation')} />
      <CardContent>
        <CaseTitleArea>
          <Title>{__('Order added')}</Title>
          <CaseId>
            <CaseIdLabel>{__('Id')}:</CaseIdLabel>
            <CaseIdText>{props.customerData.id}, </CaseIdText>
            <CaseIdLabel>{__('token')}:</CaseIdLabel>
            <CaseIdText>{props.customerData.token}</CaseIdText>
          </CaseId>
        </CaseTitleArea>

        <InformationTitle>{__('Info')}</InformationTitle>
        <UserDataList customerData={props.customerData} />

        <ButtonStyles>
          <Button info onClick={() => props.history.push('/admin/cases/open')}>
            <ButtonTitle>{__('Go to open jobs')}</ButtonTitle>
          </Button>
          <Button cta onClick={() => props.history.push(`/case-status/${props.customerData.token}`)}>
            <ButtonTitle>{__('Case details')}</ButtonTitle>
          </Button>
        </ButtonStyles>
      </CardContent>
    </CustomCard>
  );
};

const mapStateToProps = (state: any) => ({ customerData: state.customerData });

export default connect(mapStateToProps, {
  saveCustomerData: saveManualCase,
})(NewCaseDone);
