import { EscalationData } from '@adac/core-model';
import { SAVE_MANUAL_CASE } from '../actions/saveManualCase';

export const SAVE_MANUAL_CASE_ASYNC = 'SAVE_MANUAL_CASE_ASYNC';

export const customerDataReducer = (previousState: null | undefined, { type, payload }: { type: string; payload: EscalationData }) => {
  if (type === SAVE_MANUAL_CASE) {
    return payload;
  }
  return previousState || null;
};
