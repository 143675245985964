import { Button as MuiButton } from '@material-ui/core';
import { getApiRoutes, __, Company } from '@adac/core-model';
import DriverIcon from '@material-ui/icons/DriveEta';
import { RouteRecordProps } from '../../types/react-router-admin';

export const CompanyDriverListButton = ({ record, history: { push } }: RouteRecordProps<Company>) => (
  <MuiButton onClick={() => push(getApiRoutes().companyAdmin.editDriversList(record.id))}>
    <DriverIcon />
    {__('List Drivers')}
  </MuiButton>
);
