import { USERS_BLOCKED } from '../dataProvider/resources';

interface Props {
  type: string;
  payload: any;
}

export default (previousState: null | undefined, { payload, type }: Props) => {
  if (type === USERS_BLOCKED) {
    return payload;
  }
  return previousState || null;
};
