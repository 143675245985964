import React from 'react';
import { CommentPlaceholder, Loader, useAsyncAction, View } from '@adac/core-view';
import { AdminLogParamObject, AdminLogRecord, formatDate, __ } from '@adac/core-model';
import styled from 'styled-components';
import { getPreviousFullReportUrls } from '../../dataProvider/statistics';
import StoresContext from '../../stores';
import CaseStatusCommentItem, { CaseStatusCommentItemWrapper } from '../cases/caseStatusComponents/CaseStatusCommentItem';

const LayoutStyles = styled(View)`
  width: max-content;
  max-width: 400px;
`;

const getReportName = ({ url }: AdminLogRecord) => {
  if (url.match(/\/fullreport/)) return __('Download full database');
  if (url.match(/\/estimations/)) return __('Download estimations');
  return url;
};

const formatParam = (key: string, value: string) => {
  switch (key) {
    case 'from':
      return `${formatDate(value)}`;
    case 'to':
      return ` -> ${formatDate(value)}`;
    default:
      return `${__(key)}:${__(value)}`;
  }
};

const ParamObjectDetail = (params?: AdminLogParamObject) => {
  if (!params) return null;
  return (
    <View tiny>
      {Object.entries(params)
        .map((param) => formatParam(...param))
        .join(' ')}
    </View>
  );
};

const formatFileName = (url?: string) => {
  if (!url) return '';
  const fileName = url.replace(/.*\//, '');
  const prefix = fileName.match(/(?<prefix>.*)(?<timestamp>\d{4}-\d{2}-\d{2}[ T]\d{2}:\d{2}:\d{2})(?<postfix>.*)/);
  return [prefix?.groups?.prefix, formatDate(prefix?.groups?.timestamp), prefix?.groups?.postfix].join('');
};

const getUrl = (item: AdminLogRecord) => {
  try {
    return item?.data?.url || item?.response?.url;
  } catch {
    return '';
  }
};

const CommentPlaceholderComponent = () => (
  <CaseStatusCommentItemWrapper style={{ height: '120px' }}>
    <CommentPlaceholder bgColor='#b2b2b2' fgColor='#f3f3f3' />
  </CaseStatusCommentItemWrapper>
);

export const PreviousFullReportList = () => {
  const stores = React.useContext(StoresContext);
  const userId = stores.auth.userId;

  const { resource: previousReports, isLoading } = useAsyncAction(async () => getPreviousFullReportUrls(userId));
  if (!previousReports || !previousReports.length) return null;

  return (
    <LayoutStyles>
      <Loader isLoading={isLoading} count={3} component={CommentPlaceholderComponent}>
        {previousReports.map((item) => (
          <a href={getUrl(item)} target='_download' title={__('Download full database')}>
            <CaseStatusCommentItem {...item} name={getReportName(item)} key={`${item.id}_${item.createdAt.toString()}`}>
              <>{formatFileName(getUrl(item))}</>
              <ParamObjectDetail {...item?.data?.params} />
            </CaseStatusCommentItem>
          </a>
        ))}
      </Loader>
    </LayoutStyles>
  );
};
